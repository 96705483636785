import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2f8040a6 = () => interopDefault(import('../pages/collection/index.vue' /* webpackChunkName: "pages/collection/index" */))
const _2dd30312 = () => interopDefault(import('../pages/copyright.vue' /* webpackChunkName: "pages/copyright" */))
const _c23d667a = () => interopDefault(import('../pages/cp909/index.vue' /* webpackChunkName: "pages/cp909/index" */))
const _09fd5b13 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _761e0f92 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _59f5de26 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _64c8c20e = () => interopDefault(import('../pages/success.vue' /* webpackChunkName: "pages/success" */))
const _06b9cb6e = () => interopDefault(import('../pages/termsofuse.vue' /* webpackChunkName: "pages/termsofuse" */))
const _2c128193 = () => interopDefault(import('../pages/cp909/comments.vue' /* webpackChunkName: "pages/cp909/comments" */))
const _e4dab1a0 = () => interopDefault(import('../pages/cp909/desc.vue' /* webpackChunkName: "pages/cp909/desc" */))
const _d68542b8 = () => interopDefault(import('../pages/cp909/feedback.vue' /* webpackChunkName: "pages/cp909/feedback" */))
const _3074ad3e = () => interopDefault(import('../pages/cp909/kino.vue' /* webpackChunkName: "pages/cp909/kino" */))
const _17e1a83a = () => interopDefault(import('../pages/profile/favs.vue' /* webpackChunkName: "pages/profile/favs" */))
const _7d234c1d = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _20702530 = () => interopDefault(import('../pages/collection/_type/index.vue' /* webpackChunkName: "pages/collection/_type/index" */))
const _26a382c1 = () => interopDefault(import('../pages/track/_id.vue' /* webpackChunkName: "pages/track/_id" */))
const _2325b398 = () => interopDefault(import('../pages/collection/_type/_id.vue' /* webpackChunkName: "pages/collection/_type/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/collection",
    component: _2f8040a6,
    name: "collection"
  }, {
    path: "/copyright",
    component: _2dd30312,
    name: "copyright"
  }, {
    path: "/cp909",
    component: _c23d667a,
    name: "cp909"
  }, {
    path: "/privacy",
    component: _09fd5b13,
    name: "privacy"
  }, {
    path: "/profile",
    component: _761e0f92,
    name: "profile"
  }, {
    path: "/search",
    component: _59f5de26,
    name: "search"
  }, {
    path: "/success",
    component: _64c8c20e,
    name: "success"
  }, {
    path: "/termsofuse",
    component: _06b9cb6e,
    name: "termsofuse"
  }, {
    path: "/cp909/comments",
    component: _2c128193,
    name: "cp909-comments"
  }, {
    path: "/cp909/desc",
    component: _e4dab1a0,
    name: "cp909-desc"
  }, {
    path: "/cp909/feedback",
    component: _d68542b8,
    name: "cp909-feedback"
  }, {
    path: "/cp909/kino",
    component: _3074ad3e,
    name: "cp909-kino"
  }, {
    path: "/profile/favs",
    component: _17e1a83a,
    name: "profile-favs"
  }, {
    path: "/",
    component: _7d234c1d,
    name: "index"
  }, {
    path: "/collection/:type",
    component: _20702530,
    name: "collection-type"
  }, {
    path: "/track/:id?",
    component: _26a382c1,
    name: "track-id"
  }, {
    path: "/collection/:type/:id",
    component: _2325b398,
    name: "collection-type-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
