export default {
  methods: {
    lang(t, l) {
      const list = {
        'main': { ru: 'Главная' },
        'radio': { ru: 'Радио' },
        'genres': { ru: 'Жанры' },
        'collections': { ru: 'Подборки' },
        'charts': { ru: 'Чарты' },
        'albums': { ru: 'Альбомы' },
        'artists': { ru: 'Исполнители' },
        'play': { ru: 'Воспроизвести' },
        'pause': { ru: 'Пауза' },
        'download': { ru: 'Скачать' },
        'related': { ru: 'Похожие' },
        'song': { ru: 'Песня' },
        'artist': { ru: 'Артист' },
        'duration': { ru: 'Продолжительность' },
        'more': { ru: 'Еще' },
        'profile': { ru: 'Профиль' },
        'search': { ru: 'Поиск' },
        'tracks': { ru: 'Треки' },
        'favorites': { ru: 'Избранное' },
        'logout': { ru: 'Выйти' },
      }

      return list[t][l]
    },
    postUrl(id, title) {
      if (title) {
        title = title.indexOf('(') >= 0
          ? title.slice(0, title.indexOf('(') + 1)
          : title
        const str = title
          .trim()
          .toLowerCase()
          .replace(/[^a-zа-я0-9]/g, ' ')
          .replace(/ {2,}/g, ' ')
        const c = {
          а: 'a',
          б: 'b',
          в: 'v',
          г: 'g',
          д: 'd',
          е: 'e',
          ё: 'jo',
          ж: 'zh',
          з: 'z',
          и: 'i',
          й: 'j',
          к: 'k',
          л: 'l',
          м: 'm',
          н: 'n',
          о: 'o',
          п: 'p',
          р: 'r',
          с: 's',
          т: 't',
          у: 'u',
          ф: 'f',
          х: 'h',
          ц: 'c',
          ч: 'ch',
          ш: 'sh',
          щ: 'shch',
          ъ: '',
          ы: 'y',
          ь: '',
          э: 'e',
          ю: 'ju',
          я: 'ja',
          ' ': '-',
          ';': '',
          ':': '',
          ',': '',
          '—': '-',
          '–': '-',
          '.': '',
          '«': '',
          '»': '',
          '"': '',
          "'": '',
          '@': ''
        }
        let newStr = ''
        for (let i = 0; i < str.length; i++) {
          const ch = str.charAt(i)
          newStr += ch in c ? c[ch] : ch
        }
        newStr = newStr.replace(/-$/g, '')
        return `${id}-${newStr}`
      } else {
        return `${id}`
      }
    },
  }
}
